@tailwind base;
@tailwind components;
@font-face { font-family: naslov_evanston; src: url('./Fonts/EvanstonTavern-1846Medium.ttf'); }
@font-face { font-family: digitalLCD; src: url('./Fonts/Seven_Segment.ttf'); }
@font-face { font-family: text_oswald; src: url('./Fonts/Oswald-VariableFont_wght.ttf'); }
@tailwind utilities;

body {
  margin: 0;
  font-family: text_oswald;
}
.svgLoader {
     animation: spin 0.5s linear infinite;
     margin: auto;
   }
   .divLoader {
     width: 100vw;
     height: 100vh;
     display: flex;
     align-items: center;
     justify-content: center;
     z-index: 9999;
   }

   @keyframes spin {
     0% { transform: rotate(0deg); }
     100% { transform: rotate(360deg); }
   }

@layer components {
    .margin_from_menu{
        @apply mt-20
    }
     .menuButton {
        @apply text-zelena font-medium text-xl font-sans h-12 text-center flex items-center justify-center hover:text-modra hover:text-2xl transition-all duration-200 w-3/4 lg:max-w-max
      }
     .naslovi {
         @apply text-4xl lg:text-5xl py-6 font-medium font-naslov_evanston text-modra
     }
     .podstavki {
         @apply py-3 text-xl w-full leading-normal text-justify
         }
     .textpodnaslovom {
        @apply mt-10 text-xl max-w-5xl text-center px-2 leading-relaxed text-gray-700
         }
      .ikone-ikona{
            @apply text-5xl text-modra
        }
     .ikone-naslov {
          @apply text-2xl font-semibold text-center
     }
     .ikone-text {
        @apply text-lg max-w-sm flex justify-center items-center text-center
     }
    .slides{
        @apply transition-all duration-200 scale-75 m-auto flex flex-col gap-2 items-center align-middle
    }
    .scaleup {
        @apply scale-100
    }
    .crtaSpodaj {
        @apply border-b-2 border-modra
    }
    .input{
        @apply rounded bg-white p-1 font-mono text-black
    }
    .inputLabel {
        @apply py-2 text-2xl font-medium
        }
    .btn-fill {
        transition: 0.25s;
        border-radius: 8px;
        cursor: pointer;
    }
    .btn-fill:hover {
        background: transparent;
        box-shadow: inset 0 0 0 10px var(--odtenek2);
        color: white;
        @apply bg-modra text-xl
    }
    .paragraf {
        @apply leading-relaxed
    }
    .steVedeli {
        @apply text-4xl py-4 text-zelena font-digitalLCD tracking-widest
    }
    .dejstvo {
        font-size: 31px; /* 32px */
        line-height: 2.1rem; /* 36px */
        @apply font-medium text-modra
    }
    .element1{
      position:absolute;
      top: 0; bottom: 0; left: 0; right: 0;
      margin: auto;
      height: 290px;
    }
    .justifytest {
    
        @apply text-xl text-gray-700 font-medium py-2 px-2 w-full lg:w-1/2 sm:w-9/12 text-justify
    }

}
